import "core-js/modules/es6.object.define-property";
import "core-js/modules/es6.object.define-properties";
import "core-js/modules/es7.object.get-own-property-descriptors";
import "core-js/modules/es6.array.for-each";
import "core-js/modules/es6.array.filter";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.object.keys";
import _defineProperty from "/var/lib/jenkins/workspace/Baseinfo_Front_Jixiang_E1/node_modules/@babel/runtime-corejs2/helpers/esm/defineProperty.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
/*
 * @Description: 消息中心
 * @Author: rhd
 * @Date: 2024-10-08 18:23:51
 * @LastEditors: rhd
 * @LastEditTime: 2024-10-09 11:31:02
 */
var basePre = 'operation-center';
var pre = 'message-center';
var meta = {
  auth: true,
  closable: true,
  showMenu: false,
  refresh: true,
  activePath: "/".concat(basePre, "/").concat(pre, "/menu")
};
export default [{
  path: "/".concat(basePre, "/").concat(pre, "/service-notification-detail/:id/:type?"),
  title: '服务通知模板',
  name: 'service-notification-detail',
  meta: _objectSpread(_objectSpread({}, meta), {}, {
    activePath: "/".concat(basePre, "/").concat(pre, "/service-notification"),
    title: '服务通知模板'
  }),
  component: "pages/".concat(basePre, "/").concat(pre, "/service-notification/detail.vue")
}];