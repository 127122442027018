import "core-js/modules/es6.object.define-property";
import "core-js/modules/es6.object.define-properties";
import "core-js/modules/es7.object.get-own-property-descriptors";
import "core-js/modules/es6.array.for-each";
import "core-js/modules/es6.array.filter";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.object.keys";
import _defineProperty from "/var/lib/jenkins/workspace/Baseinfo_Front_Jixiang_E1/node_modules/@babel/runtime-corejs2/helpers/esm/defineProperty.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var basePre = 'operation-center';
var pre = 'commodity';
var meta = {
  auth: true,
  closable: true,
  showMenu: false,
  refresh: true,
  activePath: "/".concat(basePre, "/").concat(pre, "/menu")
};
export default [{
  path: "/".concat(basePre, "/").concat(pre, "/dining-management/dining-detail/:id?"),
  title: '新增菜品',
  name: "dining-detail",
  meta: _objectSpread(_objectSpread({}, meta), {}, {
    activePath: "/".concat(basePre, "/").concat(pre, "/dining-management/dining"),
    title: '新增菜品'
  }),
  component: "pages/".concat(basePre, "/").concat(pre, "/dining-management/dining/detail.vue")
}, {
  path: "/".concat(basePre, "/").concat(pre, "/attribute/single/:type"),
  title: '属性分类',
  name: "single-class",
  meta: _objectSpread(_objectSpread({}, meta), {}, {
    activePath: "/".concat(basePre, "/").concat(pre, "/attribute/single"),
    title: '属性分类'
  }),
  component: "pages/".concat(basePre, "/").concat(pre, "/attribute/single/class.vue")
}, {
  path: "/".concat(basePre, "/").concat(pre, "/menu-edit/:id?"),
  title: '新增菜谱',
  name: "".concat(pre, "-menu-edit"),
  meta: _objectSpread(_objectSpread({}, meta), {}, {
    title: '新增菜谱'
  }),
  component: "pages/".concat(basePre, "/").concat(pre, "/menu/menu-edit.vue")
}, {
  path: "/".concat(basePre, "/").concat(pre, "/menu-publish/:id"),
  title: '发布菜谱',
  name: "".concat(pre, "-menu-publish"),
  meta: _objectSpread(_objectSpread({}, meta), {}, {
    title: '发布菜谱'
  }),
  component: "pages/".concat(basePre, "/").concat(pre, "/menu/menu-publish.vue")
}, {
  path: "/".concat(basePre, "/").concat(pre, "/menu-set/:id"),
  title: '设置菜谱',
  name: "".concat(pre, "-menu-set"),
  meta: _objectSpread(_objectSpread({}, meta), {}, {
    title: '设置菜谱'
  }),
  component: "pages/".concat(basePre, "/").concat(pre, "/menu/menu-set.vue")
}];